exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-balans-cakri-tsx": () => import("./../../../src/pages/balans-cakri.tsx" /* webpackChunkName: "component---src-pages-balans-cakri-tsx" */),
  "component---src-pages-bars-tocke-tsx": () => import("./../../../src/pages/bars-tocke.tsx" /* webpackChunkName: "component---src-pages-bars-tocke-tsx" */),
  "component---src-pages-duhovno-ispravljanje-kraljeznice-tsx": () => import("./../../../src/pages/duhovno-ispravljanje-kraljeznice.tsx" /* webpackChunkName: "component---src-pages-duhovno-ispravljanje-kraljeznice-tsx" */),
  "component---src-pages-energetsko-ciscenje-tsx": () => import("./../../../src/pages/energetsko-ciscenje.tsx" /* webpackChunkName: "component---src-pages-energetsko-ciscenje-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-masaza-tsx": () => import("./../../../src/pages/masaza.tsx" /* webpackChunkName: "component---src-pages-masaza-tsx" */),
  "component---src-pages-masaza-zvucnim-zdjelama-tsx": () => import("./../../../src/pages/masaza-zvucnim-zdjelama.tsx" /* webpackChunkName: "component---src-pages-masaza-zvucnim-zdjelama-tsx" */),
  "component---src-pages-metamorfna-metoda-tsx": () => import("./../../../src/pages/metamorfna-metoda.tsx" /* webpackChunkName: "component---src-pages-metamorfna-metoda-tsx" */),
  "component---src-pages-reiki-tretmani-tsx": () => import("./../../../src/pages/reiki-tretmani.tsx" /* webpackChunkName: "component---src-pages-reiki-tretmani-tsx" */),
  "component---src-pages-rekonektivno-iscjeljivanje-tsx": () => import("./../../../src/pages/rekonektivno-iscjeljivanje.tsx" /* webpackChunkName: "component---src-pages-rekonektivno-iscjeljivanje-tsx" */)
}

